
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../headeradmin';
import './Manageimages.css';
/* import { useDebouncedValue } from '@mantine/hooks'; */
import { IconSearch, IconFilter, IconCalendar, IconArrowRight, IconUpload } from "@tabler/icons-react";
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import userImage from '../../images/user.svg';
import editicon from '../../images/e.svg';
import deleteicon from '../../images/d.svg';
import { Box, Grid, TextInput, Checkbox, Group , Button, NativeSelect, Radio, FileInput} from '@mantine/core';
import { useForm } from '@mantine/form';
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'

function Manageimages() {



  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [loaded, dataLoading] = useState(false);
const [oldData, setoldData] = useState([]);
const [error, setError] = useState(false);
const user = localStorage.getItem("userAdmin")
const userName = localStorage.getItem("userNameAdmin");
const userRole = localStorage.getItem("userroleAdmin");
let now = dayjs();
/* const num = 1; */

const base_url = process.env.REACT_APP_BASE_URL;
const [imgList, setImgList] = useState([]);


function getImgs(){
  axios({method: "GET", url:base_url + "/admin/calories/get-image-url", headers: {"Authorization": `Bearer ${ user }`}, crossDomain: true}).then((response) => {
    setImgList(response.data.response)
    setoldData(response.data.response);
    dataLoading(true)
  
})
}
  

let currenttime = now.format("MM-DD-YYYY");
  const [query, setQuery] = useState('');
const [srch, search] = useState(false);
const getarr =  { value: query }


const searchResult = async (e) => {
    dataLoading(false)
   // search(false)
}

const [timer, setTimer] = useState(null)
const inputChanged = e => {
  clearTimeout(timer)
  const newTimer = setTimeout(() => {
   setQuery(e.target.value)
   dataLoading(false)
  }, 1000)
  setTimer(newTimer)
}

useEffect(() => {
    if(loaded===false) {    getImgs(); }
    if(query.length > 0){
      axios({ 
        method: "POST", 
        url:base_url+"/admin/calories/search-image",
        data:getarr,  
        crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => { 
        //console.log(response)
        if(response.data.response ==='No data found'){ setImgList([])} 
        else{ setImgList(response.data.response); dataLoading(true);};
      //  search(false)
    })
  }else{
    dataLoading(false)
    setImgList(oldData)
    if(imgList.length > 0){dataLoading(true);}
  }
}, [query, oldData, loaded]);

  const [isActive] = useState("");
  const [userId] =  " "; 
  
  const navigate = useNavigate();
  const logout = (e) => {
    let keysToRemove = ["userAdmin", "DATE", "userroleAdmin", "userNameAdmin", "emailAdmin"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k))
    navigate('/Adminlogin');
  }



const [addImages, setAddImages] = useState(false);
const [DeleteImages, setDeleteImages] = useState(false);
const [popopDelete, setPopopDelete] = useState('');
const [userImg, setUserImg] = useState(false);
const [imageAssign, setimageAssign] = useState();

const formDeleteImages = useForm();



const formAddImages = useForm({
  initialValues: {
    'file': '',
    'file_name': ''
  }
});

const [file, setFile] = useState()
const [loader2, setloader2] = useState()


function handleChange(event) {
  setFile(event)
}

function handleSubmit(e) {

 /*  console.log(picture)
  const a = {
    "file": picture,
     "file_name":  e.file_name 
  }
  console.log(a)
   axios({ method: "POST", url:base_url+"/admin/calories/upload-s3-image", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
   console.log(response); 
    if(response.data.response==="category is added Successfully"){ dataLoading(true);setAddImages(false);  formAddImages.reset()  }else{
      dataLoading(true); setError('Fail to add Images!')
    }
  })  */

  const url = base_url+"/admin/calories/upload-s3-image";
  const formData = new FormData();
  formData.append('file', file);
  formData.append('file_name', e.file_name);
  const config = {
    headers: {

      'Authorization' : `Bearer ${ user }`
    },
    crossDomain: true
  };
  axios.post(url, formData, config).then((response) => {
    //console.log(response); 
     if(response.data.response==="category is added Successfully"){ dataLoading(false);setAddImages(false);  formAddImages.reset(); setloader2(false)  }else{
       dataLoading(true); setError('Fail to add Images!')
     }
   })


}

function handleDelete(e){

  const a = {"id": '' + e}
  //console.log(a)
   axios({ method: "POST", url:base_url+"/admin/calories/delete-image", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    //console.log(response.data.response)
    if(response.data.response==="we deleted your image successfully."){  dataLoading(false);  setDeleteImages(false); setDelConfirm(false); setloader2(false)}else{
      dataLoading(true); setError('Fail to Delete Images!')
    }
  }) 
}

const [delImgUrl, setDelImgUrl] = useState();
const [delImgId, setDelImgId] = useState();
const [delConfirm, setDelConfirm] = useState();

const desablepopup = (e) =>{
  const b = document.getElementById(e);
  if( userImg === true){
    b.classList.remove('popup-active')
    setUserImg(false)
  }
}

const openpopup = (e) => {
  const b = document.getElementById(e.target.dataset.id);
if( userImg === false){
    b.classList.add('popup-active')
    setUserImg(true)
  }
  
}
  
/* function getImageFileObject(imageFile) {
 //console.log({ imageFile })
 setImgData(imageFile)
}

function runAfterImageDelete(file) {
  setImgData(null)
} */





  return (
    
    <div className="Dashboard managemeal manageimg" >
      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Manage Images </h1>
              <div className='wrapper' >
             <button value="todayfilter" onClick={(e) => {search(true); searchResult(); setQuery('')}}>All Images</button>
            </div>

            
            </div>

            
            <div className='right-content'>
              
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ userRole }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
              
             
            </div> 
          </div>
                 
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} placeholder="Search" icon={<IconSearch size={16} />}   onChange={(e) => {inputChanged(e)} } />
            </Grid.Col></Grid>
        </div>

        <div className='header-right'>
        <div className='filter item' >
        <div className="filter-title add-cta" onClick={()=> ( setAddImages(true))}> + Add Image </div> 
        </div> 

      </div>
      
     
      </div>
      <div className='main-content'>
        <div className='imgs-col-container'>
      {loaded === false ? <Loader /> : 
        <>{imgList !=='No data found' && imgList?.map((s, i) => ( <>
             <div className='img-col' key={i} id={i} onClick={(i)=> ( /* openpopup(i) */ setDeleteImages(true), setDelImgUrl(s.image_url), setDelImgId(s.id) ) }><div className='popup image-edit'> <span className='close' onClick={()=> (desablepopup(i))}></span><img src={s.image_url}></img> <span className='delete-cta' data-id={i} ><img src={deleteicon} /> <span className='cta-name' onClick={()=> ( handleDelete(s)  ) }> Delete Image </span></span></div> </div>
        </> ))}</>
  }
  </div>



</div>

    </div>

</div>



{ addImages=== true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach img-add'>
  <span className='close' onClick={()=> setAddImages(false)}></span>
  <div className='popup-titlebar'>
      <h2>Upload Image</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formAddImages.onSubmit((values) => (handleSubmit(values) ))}>     

  

  <div className=''>


     <TextInput placeholder='Name' label=''   {...formAddImages.getInputProps('file_name')} />
      {/* <input type="file" onChange={handleChange}/> */}
      <FileInput accept="image/png,image/jpeg,image/svg" justify="center" className='upload-img' placeholder='Upload Image' onChange={handleChange} icon={ <IconUpload size={16} style={{ 'color': '#0051a6' }}></IconUpload> }/>
</div>

        <Group justify="flex-end" mt="md">
          <Button type="submit" onClick={()=> setloader2(true)}>Save</Button>
        </Group>
      </form>


</div>
</>
):''}


{ DeleteImages === true ? (
  <>
  <div className='poup-bg'></div>
  <div className='popup-active'><div className='popup image-edit'> 
    <span className='close' onClick={()=> setDeleteImages(false)}></span>
    <img src={delImgUrl}></img> <span className='delete-cta' data-id={delImgId} ><img src={deleteicon} /> 
    <span className='cta-name' onClick={()=> ( setDelConfirm(true)  ) }> Delete Image </span>
    </span>
    </div>
    </div>
</>
):''}


{ delConfirm === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group delete-group'>
  <span className='close' onClick={()=> setDelConfirm(false)}></span>
  <div className='popup-titlebar'>
      <h2>Delete Image </h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  
    <div className='popup-content'>
      <p>Are you sure you want to delete Image <strong>{ popopDelete }</strong> ?</p>
      
    </div>

        <Group justify="flex-end" mt="md">
          <Button className='cancel-cta' onClick={()=> setDelConfirm(false)}>Cancel</Button>
          <Button  type='submit' onClick={()=> (handleDelete(delImgId), setloader2(true))} >Yes</Button>
        </Group>
 


</div>
</>
):''}

{loader2 === true ? <div className="small-loader"><Loader ></Loader></div> : ''}

</div>








    
  );

}





export default Manageimages;
