import React from "react";
import logo from './images/logo.png'
import { useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { IconArrowBackUp } from "@tabler/icons-react";
import "./header.css";
import coachIco from './images/coach.svg';
import { useEffect } from "react";


function Header(){

    const [backL, bck] = useState();
    const location = useLocation().pathname.toLowerCase();
  
  //  console.log(location)
  const windowLoad  = (e) => {
    if(location==='/user' || location==='/user/'){
        bck("/Dashboard"); 
    };

}

const refresh = () =>  ( setTimeout(function(){window.location.reload(true)}, 100));
const setGroup = () => ( localStorage.setItem('group', 'userlist'), setTimeout(()=>{localStorage.removeItem('gname')}, 500))
const setMember = () => ( localStorage.removeItem('userGroup'))
const gorupNode = localStorage.getItem('group');
const userrole = localStorage.getItem('userrole');
const user_group = localStorage.getItem('userGroup');


useEffect(() => {
   // console.log(gorupNode)

}, [])


    return(
        <div id="header" onLoad={(e) => { windowLoad() } }>
            <a href="/"  onClick={()=> ( setGroup()) }><img alt="iWel" src={logo}></img></a>
           
            {backL && location === "/user"  && (user_group === '' || user_group === null )? 
            
            <div className="backlink" status= "visible">
               <Link to={backL} onClick={()=> ( setGroup(), refresh() ) } > <IconArrowBackUp /> Back</Link>
              
            </div>
            
            :<>
            {location === '/groups' || location === '/mygroups' || location === "/dashboard" || location === "/doctors"  ?
            <div className="board-links">
              {userrole==='admin' ?
              <>
               <Link to={'/Dashboard'} onClick={() => (setGroup())} className='board-link member' activemenu={location==='/dashboard'? 'active' : '' } > <span ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" stroke="#0051A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="#0051A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22 20.9999V18.9999C21.9993 18.1136 21.7044 17.2527 21.1614 16.5522C20.6184 15.8517 19.8581 15.3515 19 15.1299M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="#0051A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</span>  Members</Link>
                
               <Link to={'/Groups'} onClick={() => (setGroup(), setMember())}  className='board-link groups' activemenu={location==='/groups'? 'active' : '' }> <span ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 19.4999C17 17.8429 14.761 16.4999 12 16.4999C9.239 16.4999 7 17.8429 7 19.4999M21 16.4999C21 15.2699 19.766 14.2129 18 13.7499M3 16.4999C3 15.2699 4.234 14.2129 6 13.7499M18 9.73591C18.2963 9.474 18.5378 9.1561 18.7108 8.8005C18.8838 8.44491 18.9847 8.05862 19.0079 7.66386C19.031 7.26911 18.9759 6.87367 18.8456 6.5003C18.7154 6.12693 18.5127 5.78298 18.249 5.48825C17.9854 5.19351 17.6661 4.95381 17.3095 4.78291C16.9529 4.61201 16.5661 4.5133 16.1712 4.49245C15.7763 4.47161 15.3812 4.52903 15.0086 4.66142C14.636 4.79382 14.2932 4.99857 14 5.26391M6 9.73591C5.4123 9.20405 5.05896 8.46123 5.01717 7.6697C4.97538 6.87818 5.24853 6.10228 5.77696 5.5115C6.30539 4.92071 7.04614 4.56306 7.83741 4.51667C8.62868 4.47028 9.40616 4.73892 10 5.26391M12 13.4999C11.2044 13.4999 10.4413 13.1838 9.87868 12.6212C9.31607 12.0586 9 11.2956 9 10.4999C9 9.70426 9.31607 8.94119 9.87868 8.37858C10.4413 7.81598 11.2044 7.49991 12 7.49991C12.7956 7.49991 13.5587 7.81598 14.1213 8.37858C14.6839 8.94119 15 9.70426 15 10.4999C15 11.2956 14.6839 12.0586 14.1213 12.6212C13.5587 13.1838 12.7956 13.4999 12 13.4999Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg></span>  Groups</Link>
               <Link to={'/Doctors'}  className='board-link coaches' activemenu={location==='/doctors'? 'active' : '' }> <span ><img alt="Doctors" src={coachIco} /></span>  Doctors</Link>
               </> 
               : <Link to={'/Mygroups'} onClick={() => (setGroup(), setMember())}  className='board-link groups' activemenu={location==='/Mygroups'? 'active' : '' }> <span ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 19.4999C17 17.8429 14.761 16.4999 12 16.4999C9.239 16.4999 7 17.8429 7 19.4999M21 16.4999C21 15.2699 19.766 14.2129 18 13.7499M3 16.4999C3 15.2699 4.234 14.2129 6 13.7499M18 9.73591C18.2963 9.474 18.5378 9.1561 18.7108 8.8005C18.8838 8.44491 18.9847 8.05862 19.0079 7.66386C19.031 7.26911 18.9759 6.87367 18.8456 6.5003C18.7154 6.12693 18.5127 5.78298 18.249 5.48825C17.9854 5.19351 17.6661 4.95381 17.3095 4.78291C16.9529 4.61201 16.5661 4.5133 16.1712 4.49245C15.7763 4.47161 15.3812 4.52903 15.0086 4.66142C14.636 4.79382 14.2932 4.99857 14 5.26391M6 9.73591C5.4123 9.20405 5.05896 8.46123 5.01717 7.6697C4.97538 6.87818 5.24853 6.10228 5.77696 5.5115C6.30539 4.92071 7.04614 4.56306 7.83741 4.51667C8.62868 4.47028 9.40616 4.73892 10 5.26391M12 13.4999C11.2044 13.4999 10.4413 13.1838 9.87868 12.6212C9.31607 12.0586 9 11.2956 9 10.4999C9 9.70426 9.31607 8.94119 9.87868 8.37858C10.4413 7.81598 11.2044 7.49991 12 7.49991C12.7956 7.49991 13.5587 7.81598 14.1213 8.37858C14.6839 8.94119 15 9.70426 15 10.4999C15 11.2956 14.6839 12.0586 14.1213 12.6212C13.5587 13.1838 12.7956 13.4999 12 13.4999Z" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg></span>  Groups</Link> }
            </div>: 
                  <div className="backlink" status="visible">{userrole==='admin' ?
               (<>
                {location === "/memberlist" ? <Link to={'/Groups'} onClick={()=> localStorage.removeItem('userGroup')}  > <IconArrowBackUp /> Back</Link> : ''}
                {location === "/user" ? <Link to={'/Memberlist'} onClick={() => refresh() } > <IconArrowBackUp /> Back</Link> : ''}
                </>)
               :  (<>{location === "/memberlist" ? <Link to={'/Mygroups'} onClick={()=> localStorage.removeItem('userGroup')}  > <IconArrowBackUp /> Back</Link> : ''}
                {location === "/user" ? <Link to={'/Memberlist'} onClick={() => refresh() } > <IconArrowBackUp /> Back</Link> : ''}</>) }
             </div>
               
               
             
            }</>
            }
        </div>

        

    );
}

export default Header;