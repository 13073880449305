
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../headeradmin';
import './Managemeal.css';
import { DataTable } from 'mantine-datatable';
/* import { useDebouncedValue } from '@mantine/hooks'; */
import { IconSearch, IconFilter, IconCalendar, IconArrowRight } from "@tabler/icons-react";
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import userImage from '../../images/user.svg';
import editicon from '../../images/e.svg';
import deleteicon from '../../images/d.svg';
import { useForm } from '@mantine/form';
import { Box, Grid, TextInput, Checkbox, Group , Button, NativeSelect, Select, Radio} from '@mantine/core';



function Managemeal() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [profileData, setProfileData] = useState([]);
const [oldData, setoldData] = useState([]);

const [loaded1, dataLoading1] = useState(false);
const [loaded, dataLoading] = useState(true);
const [error, setError] = useState(false);
const user = localStorage.getItem("userAdmin")
const [numUsers, setNumUsers] = useState();
const userName = localStorage.getItem("userNameAdmin");
const userRole = localStorage.getItem("userroleAdmin");
let now = dayjs();
/* const num = 1; */
const [a, seta] = useState('1');
const [itemInPager, setItemInPager] = useState(25);
const [num1, setNum] = useState(0);
const base_url = process.env.REACT_APP_BASE_URL;

function getData(e, f){
  axios({ method: "GET", url:base_url+"/admin/calories/get-all-meal/"+e+"/"+f, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      setProfileData(response.data.response);  setoldData(response.data.response); setNumUsers(response.data.count)
      if(response.data.status===200){ dataLoading(true); }
  })
  
}

const [categoryList, setCategoryList] = useState([]);
const [imgList, setImgList] = useState([]);

function getCat(){
  axios({method: "GET", url:base_url + "/admin/calories/get-category-list", headers: {"Authorization": `Bearer ${ user }`}, crossDomain: true}).then((response) => {
    setCategoryList(response.data.response)
})
}

function getImgs(){
  axios({method: "GET", url:base_url + "/admin/calories/get-image-url", headers: {"Authorization": `Bearer ${ user }`}, crossDomain: true}).then((response) => {
    setImgList(response.data.response)
})
}

  const PAGE_SIZE = 15;
  const [records, setRecords] = useState(profileData.slice(0, PAGE_SIZE));
  const [query, setQuery] = useState('');
const getarr =  { 'value': query}

const searchResult = async (e) => {
    dataLoading(false)
   // search(false)
}

const [timer, setTimer] = useState(null)
const inputChanged = e => {
  clearTimeout(timer)
  const newTimer = setTimeout(() => {
   setQuery(e.target.value)
   dataLoading(false)
  }, 1000)
  setTimer(newTimer)
}

const [pagerVis, setPagerVis] = useState(true);
     useEffect(() => {
      
        if(query.length > 0){
          setPagerVis(false)
          axios({ method: "POST", url:base_url+"/admin/calories/search-meal",  data:getarr,  crossDomain: true, headers: { "Authorization" : `Bearer ${ user }`},})
          .then((response) => { 
           
            if(response.data.response ==='No data found'){ setProfileData([])} 
            else{ setProfileData(response.data.response)}; dataLoading(true);
          //  search(false)
        })
      }else{
        dataLoading(false)
        setProfileData(oldData)
        if(profileData.length > 0){dataLoading(true);}
        setPagerVis(true)
      }
      if(loaded===false &&  query.length < 1) { getData(0, 25);  getCat(); getImgs(); }
      setRecords( profileData)
     // console.log(profileData)
     if(loaded===true  ){
      if(parseInt(numUsers) > parseInt(itemInPager)){
if(parseInt(((parseInt(numUsers))/itemInPager), 10) < (parseInt(numUsers))/itemInPager){
seta(parseInt(((parseInt(numUsers))/itemInPager), 10)+1)}
else{
seta(parseInt(((parseInt(numUsers))/itemInPager), 10))}
}
else{
seta(1)
}
} 

      if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
        setNum(1); 
      }
      
  }, [query, loaded, oldData]);

  const [isActive] = useState("");

  const [userId] =  " "; 
  
  const navigate = useNavigate();
  const logout = (e) => {
    let keysToRemove = ["userAdmin", "DATE", "userroleAdmin", "userNameAdmin", "emailAdmin"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k))
    navigate('/Adminlogin');
  }

const pager = (e) => {
  if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
    setNum(1)
   setTimeout(()=> {getData(0, itemInPager)}, 300)
  }else{
    if(num1 < (a+1)){
    setNum(parseInt(num1)+parseInt(e))
    setTimeout(()=> {getData(num1+parseInt(e), itemInPager)}, 300)
    }else{
      setNum(a)
    }
  }
}


const [editMeal, setEditMeal] = useState(false);
const [addMeal, setAddMeal] = useState(false);
const [DeleteMeal, setDeleteMeal] = useState(false);
const [popopDelete, setPopopDelete] = useState('');
const [DeleteId, setDeleteId] = useState('');
const [addCat, setAddCat] = useState(false);
const [userImg, setUserImg] = useState();
const [imageEdit, setImgedit] = useState();
const [imageAssign, setimageAssign] = useState();
const [selectedImg, setselectedImg]= useState()

const [loader2, setloader2] = useState()


const formEditMeal = useForm({
  initialValues: {
    id:'',
    item_name: '',
    category: '',
    brand: '',
    calories_per_serving : '',
    serving_size:'',
    protein:'',
    fats:'',
    carbs:'',
    fibre:'',
    category_pics: ''
  }/* ,
  validate: {
    updated_group_phone_number: (value) => (value.length > 0 ?  value.length < 10 ? 'Invalid Contact Number' : null : null),
    updated_group_email_address: (value) => (value.length > 0 ?   /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address' : null)    
  } */
});

const formDeleteMeal = useForm();

const formAddMeal = useForm({
  initialValues: {
    item_name: '',
    category: '',
    brand: '',
    calories_per_serving : '',
    serving_size:'',
    protein:'',
    fats:'',
    carbs:'',
    fibre:'',
    category_pics: ''
  }
});

const formDeleteCat = useForm({
    initialValues: {
      id: []
    }
});
const formAddCat = useForm();

function handleSubmit(e) {
  const a = {
    "name": e.item_name,
    "category": e.category,
    "brand": e.brand,
    "calories_per_serving" : e.calories_per_serving,
    "serving_size":e.serving_size,
    "protein":e.protein,
    "fats":e.fats,
    "carbs":e.carbs,
    "fibre":e.fibre,
    "category_pics": e.category_pics
  }
  axios({ method: "POST", url:base_url+"/admin/calories/add-new-meal", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    getData(num1, itemInPager);
    if(response.data.response==="meal is added Successfully"){ setAddMeal(false);  formAddMeal.reset(); dataLoading(true)}else{
      setError('Fail to add Meal!');  dataLoading(true)
    }
    setloader2(false)
  })
  
}

function handleDelete(e){
  
  const a = {
    "id" : e.id
  }
 // console.log(a)
  axios({ method: "POST", url:base_url+"/admin/calories/delete-meal", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    getData(num1, itemInPager);
    if(response.data.response==="we deleted your meal successfully."){dataLoading(true); setDeleteMeal(false)  }else{
      dataLoading(true);setError('Fail to Delete Meal!')
    }
    setloader2(false)

  })
}

function handleEdit(e) {
  //
  
  const a = {
    "id":e.id,
    "item_name": e.item_name,
    "category": e.category===null? 'null' : e.category,
    "brand": e.brand===null? 'null' : e.brand,
    "calories_per_serving" : e.calories_per_serving===null? 'null' : e.calories_per_serving,
    "serving_size":e.serving_size===null? 'null' : e.serving_size,
    "protein":e.protein===null? 'null' : e.protein,
    "fats":e.fats===null? 'null' : e.fats,
    "carbs":e.carbs===null? 'null' : e.carbs,
    "fibre": e.fibre===null? 'null' : e.fibre,
    "category_pics": e.category_pics===null? 'null' : e.category_pics
  }
 
    axios({ method: "POST", url:base_url+"/admin/calories/edit-meal", data:a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      getData(num1, itemInPager);
      if(response.data.response==="Your meal has been successfully updated."){  setEditMeal(false); formEditMeal.reset(); dataLoading(true);  } else{
         setError('Your Changes have not been saved!'); dataLoading(true);
      }
      setloader2(false)
    }) 
  }

  function handleCat(e) {
    const a = {
      "category": e.category
    }
  
    axios({ method: "POST", url:base_url+"/admin/calories/add-category-meal", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      getCat();
      if(response.data.response==="category is added Successfully"){ dataLoading(true);setAddCat(false);  formAddCat.reset(); getCat()  }else{
       dataLoading(true); setError('Fail to add Meal!')
      }
      setloader2(false)
    })
  }
  

const [dellist, setdellist] = useState([]);
const [delCatConfirm, setDelCatConfirm]= useState(false);

  function handleCatDel(e){
    axios({ method: "POST", url:base_url+"/admin/calories/delete-category-meal", data: e, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      getCat();
      if(response.data.response==="we deleted your meal successfully."){dataLoading(true); setAddCat(false); setDelCatConfirm(false); getCat()  }else{
       dataLoading(true); setError('Fail to Delete Meal!')
      }
      setloader2(false)
      delCatConfirm([])
    })
  }



  return (
    
    <div className="Dashboard managemeal" >
      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Manage Meal </h1>
              <div className='wrapper' >
             <button value="todayfilter" onClick={(e) => {setQuery(''); dataLoading(false) }}>All Meal</button>
            </div>

            
            </div>

            
            <div className='right-content'>
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ userRole }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
              
             
            </div> 
          </div>
          
   
          
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} placeholder="Search" icon={<IconSearch size={16} />}   onChange={(e) => {inputChanged(e)} } />
            </Grid.Col></Grid>
        </div>

        <div className='header-right'>
        <div className='filter item' >
        <div className="filter-title add-cta" onClick={()=> ( setAddMeal(true), setUserImg())}> + Add Meal </div> 
        <div className="filter-title man-cat" onClick={()=> ( setAddCat(true))}> Manage Category </div> 
        </div> 

      </div>
      
     
      </div>
      <div className='main-content'>
      {loaded === false ? <Loader /> : (
      <Box >
    <DataTable
      noHeader
      records={records}
      columns={[
        { accessor: 'category_pics', render: ({category_pics}) => (<>{category_pics !==""  ? <><img className='cta-wrapper' src={ category_pics } /> </>: <span className='placeholder'></span>}</>), cellsClassName: 'category_pics', width: 140},
        { accessor: 'item_name', cellsClassName: 'item_name', width: 200},
        {accessor: 'category', cellsClassName:'category', width: 120},
        { accessor: 'brand',  cellsClassName:'brand', width: 90, render: ({brand}) => (<>{brand === 'null' ? '-' : brand }</>) },
        {accessor: 'calories_per_serving', cellsClassName:'calories_per_serving', width: 140},
        { accessor: 'serving_size',  cellsClassName:'serving_size', width: 140},
        {accessor: 'protein', cellsClassName:'protein', width: 80},
        {accessor: 'fats', cellsClassName:'fats', width: 75},
        {accessor: 'carbs', cellsClassName:'carbs', width: 75},
        {accessor: 'fibre', cellsClassName:'fibre', width: 75},
        {accessor: 'id', cellsClassName:'cta edit', render: ({id,item_name,category, brand, calories_per_serving,serving_size, protein, fats,carbs, fibre, category_pics}) => (<span onClick={()=>(setEditMeal(true), 
          
          formEditMeal.setValues({
            id: id,  
            item_name: item_name,
            category: category,
            brand: brand,
            calories_per_serving : calories_per_serving,
            serving_size:serving_size,
            protein:protein,
            fats: fats,
            carbs:carbs,
            fibre:fibre,
            category_pics: category_pics}), setUserImg(category_pics) )}><img src={editicon} /></span>), width: 75},
        {accessor: '', cellsClassName:'cta delete', render: ({id, item_name}) => (<span onClick={()=>(setDeleteMeal(true), formDeleteMeal.setValues({id: id}), setPopopDelete(item_name) )}><img src={deleteicon} /></span>), width: 75}
        
      ]}

    />

{ pagerVis === true  ? ( <>
<div className="mantine-ooem79 pager">
  <NativeSelect label="Meal display per page" defaultValue={itemInPager} onChange={(e) => {  setItemInPager( e.currentTarget.value) 
  const c = parseInt(((parseInt(numUsers))/e.currentTarget.value), 10);
  const d = parseInt(((parseInt(numUsers))/e.currentTarget.value));
  const b =   c < d ? c+1 : d;

  if(parseInt(numUsers) > parseInt(e.currentTarget.value)){
    if(parseInt(((parseInt(numUsers))/e.currentTarget.value), 10) < (parseInt(numUsers))/e.currentTarget.value){
  seta(parseInt(((parseInt(numUsers))/e.currentTarget.value), 10)+1)}
  else{
    seta(parseInt(((parseInt(numUsers))/e.currentTarget.value), 10))}
  }
  else{
  seta(1)
  }

if(num1>(b-1)){
  
setNum(parseInt(b))
getData(parseInt(b), e.currentTarget.value)

}else{
//console.log("num1 is small"+e)
setNum(num1)
getData(num1, e.currentTarget.value)

}
}} data={['25', '50', '100']} />
  <div className="mantine-Group-root mantine-kn8vaf">
  <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> { pager(-1) }} data-disbaled={num1===0 ? 'disabled' : 'visible'}  type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M7.219 8l3.3 3.3-.943.943L5.333 8l4.243-4.243.943.943-3.3 3.3z" fill="currentColor"></path></svg></button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(-1)}} data-hidden={num1===0 ? 'disabled' : 'visible'} type="button" >{parseInt(num1+1)-1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg active" type="button" >{parseInt(num1+1)}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(1)}} data-hidden={num1===(a-1) ? 'disabled' : 'visible'}  type="button" >{parseInt(num1+1)+1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> {pager(1) }} data-disbaled={num1===(a-1) ? 'disabled' : 'visible'} type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M8.781 8l-3.3-3.3.943-.943L10.667 8l-4.243 4.243-.943-.943 3.3-3.3z" fill="currentColor"></path></svg></button>
    </div>
    </div>
</>) :''}
</Box>
  )}

</div>
</div>
</div>

{ editMeal === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach edit'>
  <span className='close' onClick={()=> setEditMeal(false)}></span>
  <div className='popup-titlebar'>
      <h2>Edit Meal</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formEditMeal.onSubmit((values) => (handleEdit(values) ))}>     

<div className='two-col-grp'>
  <TextInput className=' two-col invisible'  label="id" required placeholder="id" {...formEditMeal.getInputProps('id')}/>
  <TextInput className=' two-col'  label="Name" required placeholder="Name" {...formEditMeal.getInputProps('item_name')}/>
  <TextInput className='two-col'  label="Brand"  placeholder="Brand" {...formEditMeal.getInputProps('brand')}/>
        
  <Select
      label="Category" className='two-col'
      placeholder="Category"
      data={categoryList !== "No data found" ?  categoryList.map((ob, j)=> {return {...ob, "value": ob.category, "label": ob.category  }}) : []}
       {...formEditMeal.getInputProps('category')} 
      ></Select>


        <TextInput className='two-col'  label="Calories/Serving"  placeholder="Calories/Serving" {...formEditMeal.getInputProps('calories_per_serving')}/>
        <TextInput className='two-col'  label="Serving Size" placeholder="Serving Size" {...formEditMeal.getInputProps('serving_size')} />
        
        
   


        <TextInput className='two-col'  label="Protein"  placeholder="Protein" {...formEditMeal.getInputProps('protein')}/>
        <TextInput className='two-col'  label="Fats"  placeholder="Fats" {...formEditMeal.getInputProps('fats')}/>
        <TextInput className='two-col'  label="Carbs"  placeholder="Carbs" {...formEditMeal.getInputProps('carbs')}/>
        <TextInput className='two-col'  label="Fibre"  placeholder="Fibre" {...formEditMeal.getInputProps('fibre')}/>
</div>

<div className='img-select'>
            <div className='clickble-area' onClick={()=> { setImgedit(true);  }}> <span className={ userImg ? 'image active' : 'image' }>{ userImg ? <><img src={ userImg } /></> :''} </span>Edit Image </div>
            <TextInput  label=""  placeholder="" {...formEditMeal.getInputProps('category_pics')}/>
  </div>

        <Group justify="flex-end" mt="md">
          <Button type="submit"  onClick={()=>setloader2(true)}>Save</Button>
        </Group>
      </form>


</div>
</>
):''}

{ addMeal=== true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach memrbes-add'>
  <span className='close' onClick={()=> setAddMeal(false)}></span>
  <div className='popup-titlebar'>
      <h2>Add Meal</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formAddMeal.onSubmit((values) => (handleSubmit(values) ))}>     

  

  <div className='two-col-grp'>

  <TextInput className=' two-col'  label="Name" required placeholder="Name" {...formAddMeal.getInputProps('item_name')}/>
  <TextInput className='two-col'  label="Brand"  placeholder="Brand" {...formAddMeal.getInputProps('brand')}/>
        

  <Select
      label="Category" className='two-col'
      placeholder="Category"
      data={ categoryList !== "No data found" ? categoryList.map((ob, j)=> {return {...ob, "value": ob.category, "label": ob.category  }}) : []}
       {...formAddMeal.getInputProps('category')} 
      >
         
      </Select>
        
        <TextInput className='two-col'  label="Calories/Serving"  placeholder="Calories/Serving" {...formAddMeal.getInputProps('calories_per_serving')}/>
        <TextInput className='two-col'   label="Serving Size"   placeholder="Serving Size" {...formAddMeal.getInputProps('serving_size')} />


        <TextInput className='two-col'  label="Protein"  placeholder="Protein" {...formAddMeal.getInputProps('protein')}/>
        <TextInput className='two-col'  label="Fats"  placeholder="Fats" {...formAddMeal.getInputProps('fats')}/>
        <TextInput className='two-col'  label="Carbs"  placeholder="Carbs" {...formAddMeal.getInputProps('carbs')}/>
        <TextInput className='two-col'  label="Fibre"  placeholder="Fibre" {...formAddMeal.getInputProps('fibre')}/>
</div>

  <div className='img-select'>
            <div className='clickble-area' onClick={()=> { setimageAssign(true);  }}> <span className={ userImg ? 'image active' : 'image' }>{ userImg ? <><img src={ userImg } /></> :''}</span>Select Image </div>
            <TextInput  label=""  placeholder="" {...formAddMeal.getInputProps('category_pics')}/>
  </div>


   

        <Group justify="flex-end" mt="md">
          <Button type="submit"  onClick={()=>setloader2(true)}>Save</Button>
        </Group>
      </form>


</div>
</>
):''}



{ imageEdit === true ? (
  <>
  <div className='poup-bg '></div>
<div className='popup add-group delete-group img-popup'>
  <span className='close' onClick={()=> setImgedit('false')}></span>
  <div className='popup-titlebar'>
      <h2>Update Image </h2>
  </div>
  <form >  
    <div className='popup-content'>
       
    <Radio.Group>{imgList !=='No data found' && imgList.map((i, index)=>(
      
           <Radio  value={i.image_url} label="" imgsrc="" onClick={(e)=> (setImgedit('false'), formEditMeal.setValues({ category_pics: e.currentTarget.value}), setUserImg(e.currentTarget.value))} style={{ backgroundImage :  'url("'+i.image_url+'")', backgroundSize: 'contain' }}  /> 
          
        ))}
        </Radio.Group>
    </div>


 
</form>

</div>
</>
):''}

{ imageAssign === true ? (
  <>
  <div className='poup-bg '></div>
<div className='popup add-group delete-group img-popup'>
  <span className='close' onClick={()=> setimageAssign('false')}></span>
  <div className='popup-titlebar'>
      <h2>Update Image </h2>
  </div>
  <form >  
    <div className='popup-content'>
       
    <Radio.Group>{imgList !=='No data found' && imgList.map((i, index)=>(
      
           <Radio  value={i.image_url} label="" imgsrc="" onClick={(e)=> (setimageAssign('false'), formAddMeal.setValues({ category_pics: e.currentTarget.value}), setUserImg(e.currentTarget.value))} style={{ backgroundImage :  'url("'+i.image_url+'")', backgroundSize: 'contain' }}  /> 
          
        ))}
        </Radio.Group>
    </div>


 
</form>

</div>
</>
):''}



{ DeleteMeal === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group delete-group'>
  <span className='close' onClick={()=> setDeleteMeal('')}></span>
  <div className='popup-titlebar'>
      <h2>Delete Meal </h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formDeleteMeal.onSubmit((values) => (handleDelete(values) ))}>  
    <div className='popup-content'>
      <p>Are you sure you want to delete meal <strong>{ popopDelete }</strong> ?</p>
      <TextInput className=' two-col invisible'  label="id" required placeholder="id" {...formDeleteMeal.getInputProps('id')}/>
    </div>

        <Group justify="flex-end" mt="md">
          <Button className='cancel-cta' onClick={()=> (setDeleteMeal(''))}>Cancel</Button>
          <Button  type='submit' onClick={()=>setloader2(true)} >Yes</Button>
        </Group>
 
</form>

</div>
</>
):''}



{addCat === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach memrbes-add'>
  <span className='close' onClick={()=> setAddCat(false)}></span>
  <div className='popup-titlebar'>
      <h2>Manage Categories</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formAddCat.onSubmit((values) => (handleCat(values) ))}>     

  <div className='search-field-formatting'>
  <TextInput withAsterisk label="" {...formAddCat.getInputProps('category')}   placeholder="Add Category "  />
       
  <Group justify="flex-end" mt="md">
          <Button type="submit"  onClick={()=>setloader2(true)}>Save</Button>
        </Group>


        </div>


        

        </form>

        <form onSubmit={formDeleteCat.onSubmit((values) => (setdellist(values), setDelCatConfirm(true)) )}>         
<Checkbox.Group className='members-list11'
      label=""
      description="" 
       {...formDeleteCat.getInputProps('id')} >
      <Group>
       {categoryList !=='No data found' && categoryList?.map((s)=> 
       (<>
       <Checkbox value={s.id.toFixed(0)}  label={s.category} /> </>)
        )} 
       
      </Group>
    </Checkbox.Group>


        <Group justify="flex-end" mt="md">
          <Button type="submit"  >Delete</Button>
        </Group>
      </form>


</div>
</>
):''}


{ delCatConfirm === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group delete-group'>
  <span className='close' onClick={()=> setDelCatConfirm(false)}></span>
  <div className='popup-titlebar'>
      <h2>Delete Category </h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  
    <div className='popup-content'>
      <p>Are you sure you want to delete Category?</p>
      
    </div>

        <Group justify="flex-end" mt="md">
          <Button className='cancel-cta' onClick={()=> setDelCatConfirm(false)}>Cancel</Button>
          <Button  type='submit' onClick={()=> (handleCatDel(dellist), setloader2(true))} >Yes</Button>
        </Group>
 


</div>
</>
):''}


{loader2 === true ? <div className="small-loader"><Loader ></Loader></div> : ''}


</div>








    
  );

}





export default Managemeal;
