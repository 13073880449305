
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../headeradmin';
import './Manageexercise.css';
import { DataTable } from 'mantine-datatable';
/* import { useDebouncedValue } from '@mantine/hooks'; */
import { IconSearch, IconFilter, IconCalendar, IconArrowRight } from "@tabler/icons-react";
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import userImage from '../../images/user.svg';
import editicon from '../../images/e.svg';
import deleteicon from '../../images/d.svg';
import { Box, Grid, TextInput, Checkbox, Group , Button, NativeSelect, Radio} from '@mantine/core';
import { useForm } from '@mantine/form';
import { isArrayLikeObject } from 'lodash';

function Manageexercise() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [profileData, setProfileData] = useState([]);
const [oldData, setoldData] = useState([]);
const [appData, setAppoData] = useState([]);
const [loaded1, dataLoading1] = useState(false);
const [loaded, dataLoading] = useState(true);
const [error, setError] = useState(false);
const user = localStorage.getItem("userAdmin")
const [numUsers, setNumUsers] = useState();
const userName = localStorage.getItem("userNameAdmin");
const userRole = localStorage.getItem("userroleAdmin");
let now = dayjs();
/* const num = 1; */

const [a, seta] = useState();

const [itemInPager, setItemInPager] = useState(25);
const [num1, setNum] = useState(0); 
const base_url = process.env.REACT_APP_BASE_URL;
function getData(e, f){
  axios({ method: "GET", url:base_url+"/admin/exercise/get-exercise-calories/"+e+"/"+f, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      setProfileData(response.data.response)
      setoldData(response.data.response); setNumUsers(response.data.count)
    //  console.log(response.data)
    // console.log("/admin/exercise/get-exercise-calories/"+e+"/"+f)
  // console.log(parseInt(((parseInt(response.data.count))/itemInPager))+ '---'+itemInPager+'--'+response.data.count)
      if(response.data.status===200){ dataLoading(true);  

      }

  })
  
}

const [imgList, setImgList] = useState([]);


function getImgs(){
  axios({method: "GET", url:base_url + "/admin/calories/get-image-url", headers: {"Authorization": `Bearer ${ user }`}, crossDomain: true}).then((response) => {
    setImgList(response.data.response)
  //  console.log(response.data.response)
})
}

const [neAppdata, setApp] = useState();

  

let currenttime = now.format("MM-DD-YYYY");
  const PAGE_SIZE = 15;
  const [records, setRecords] = useState(profileData.slice(0, PAGE_SIZE));
  const [query, setQuery] = useState('');
const [srch, search] = useState(false);
const getarr =  {"value" : query};


const searchResult = async (e) => {
    dataLoading(false)
   // search(false)
}

const [timer, setTimer] = useState(null)
const inputChanged = e => {
  clearTimeout(timer)
  const newTimer = setTimeout(() => {
   setQuery(e.target.value)
   dataLoading(false)
  }, 1000)
  setTimer(newTimer)
}

const [pagerVis, setPagerVis] = useState(true);
     useEffect(() => {
      
        if(query.length > 0){
          //console.log(getarr)
          setPagerVis(false)
          axios({ 
            method: "POST", 
            url:base_url+"/admin/exercise/search-exercise",
            data:getarr,  
            crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => { 
            //console.log(response)
            if(response.data.response ==='No data found'){ setProfileData([])} 
            else{ setProfileData(response.data.response)}; dataLoading(true);
          //  search(false)
        })
      }else{
        dataLoading(false)
        setProfileData(oldData)
        if(profileData.length > 0){dataLoading(true);}
        setPagerVis(true)
      }
      if(loaded===false &&  query.length < 1) { getData(num1, itemInPager); getImgs(); }
      setRecords( profileData)
      if(loaded===true  ){
                if(parseInt(numUsers) > parseInt(itemInPager)){
          if(parseInt(((parseInt(numUsers))/itemInPager), 10) < (parseInt(numUsers))/itemInPager){
        seta(parseInt(((parseInt(numUsers))/itemInPager), 10)+1)}
        else{
          seta(parseInt(((parseInt(numUsers))/itemInPager), 10))}
        }
        else{
        seta(1)
        }
      } 
     // console.log(profileData)
      if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
        setNum(1); 
      }
      
  }, [query, loaded, oldData]);

  const [isActive] = useState("");

  const [userId] =  " "; 
  
  const navigate = useNavigate();
  const logout = (e) => {
    let keysToRemove = ["userAdmin", "DATE", "userroleAdmin", "userNameAdmin", "emailAdmin"];
    keysToRemove.forEach(k =>
      localStorage.removeItem(k))
    navigate('/Adminlogin');
  }



const pager = (e) => {
  

  if(num1===null || num1==='' || num1==='undefined' || isNaN(num1)){
    setNum(1)
   setTimeout(()=> {getData(0, itemInPager)}, 300)
  }else{
    if(num1 < (a+1)){
    setNum(parseInt(num1)+parseInt(e))
    setTimeout(()=> {getData(num1+parseInt(e), itemInPager)}, 300)
    }else{
      setNum(a)
    }
  }
}


function updatePager(e){
  
  const b = (((parseInt(numUsers))/e).toFixed());
  if(parseInt(numUsers)>b){
    seta(parseInt(b))
  }
  if(num1>b){
    setNum(parseInt(b))
  }
}

const [editExercise, setEditExercise] = useState(false);
const [addExercise, setAddExercise] = useState(false);
const [DeleteExercise, setDeleteExercise] = useState(false);
const [popopDelete, setPopopDelete] = useState('');
const [DeleteId, setDeleteId] = useState('');
const [userImg, setUserImg] = useState();
const [imageEdit, setImgedit] = useState();
const [imageAssign, setimageAssign] = useState();

const[loader2, setloader2] = useState();

const formEditExercise = useForm({
  initialValues: {
    id:'',
    exercise: '',
    lbs130: '',
    lbs155: '',
    lbs180 : '',
    lbs205:'',
    exercise_pics: ''
  }/* ,
  validate: {
    updated_group_phone_number: (value) => (value.length > 0 ?  value.length < 10 ? 'Invalid Contact Number' : null : null),
    updated_group_email_address: (value) => (value.length > 0 ?   /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address' : null)    
  } */
});

const formDeleteExercise = useForm();

const formAddExercise = useForm({
  initialValues: {
    exercise: '',
    lbs130: '',
    lbs155: '',
    lbs155 : '',
    lbs205:'',
    exercise_pics: ''
  }
});

const formAssign = useForm();

function handleSubmit(e) {
  const a = {
    "exercise": e.exercise ? e.exercise : '',
    '130lbs':  e.lbs130 ? e.lbs130 : '',
    '155lbs': e.lbs155 ? e.lbs155 : '',
    '180lbs' : e.lbs180 ? e.lbs180 : '',
    '205lbs':e.lbs205 ? e.lbs205 : '',
    "exercise_pics": e.exercise_pics ? e.exercise_pics : ''
  }
  
  axios({ method: "POST", url:base_url+"/admin/exercise/add-exercise", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
   
    if(response.data.response==="Exercise is added Successfully"){ setAddExercise(false);  formAddExercise.reset()  }else{
       setError('Fail to add Exercise!')
    }
    dataLoading(false)
    setloader2(false)
    setUserImg()
  })
}

function handleDelete(e){
  
  const a = {
    "id" : e.id
  }
 // console.log(a)
  axios({ method: "POST", url:base_url+"/admin/exercise/delete-exercise", data: a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    if(response.data.response==="we deleted your exercise successfully."){ setDeleteExercise(false)  }else{
       setError('Fail to Delete Exercise!')
    }
    dataLoading(false)
    setloader2(false)
  })
}


function handleEdit(e) {
  
  const a = {
    "id":e.id,
    "exercise": e.exercise ? e.exercise : '',
    '130lbs':  e.lbs130 ? e.lbs130 : '',
    '155lbs': e.lbs155 ? e.lbs155 : '',
    '180lbs' : e.lbs180 ? e.lbs180 : '',
    '205lbs': e.lbs205 ? e.lbs205 : '',
    "exercise_pics": e.exercise_pics ? e.exercise_pics : ''
  }

    axios({ method: "POST", url:base_url+"/admin/exercise/edit-exercise", data:a, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
      if(response.data.response==="Your Exercise has been successfully updated."){  setEditExercise(false); formEditExercise.reset()  } else{
         setError('Your Changes have not been saved!')
      }
      dataLoading(false)
      setloader2(false)
      setUserImg()
    }) 
  }

  return (
    
    <div className="Dashboard managemeal" >
      <div className="page-wrpper"> 
      <Header />
    <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Manage Exercise </h1>
              <div className='wrapper' >
             <button value="todayfilter" onClick={(e) => {search(true); searchResult()}}>All Exercise</button>
            </div>

            
            </div>

            
            <div className='right-content'>
              
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ userRole }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
              
             
            </div> 
          </div>
          
   
          
          <div className='header-left'>

           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} placeholder="Search" icon={<IconSearch size={16} />}   onChange={(e) => {inputChanged(e)} } />
            </Grid.Col></Grid>
        </div>

        <div className='header-right'>
        <div className='filter item' >
        <div className="filter-title add-cta" onClick={()=> ( setAddExercise(true))}> + Add Exercise </div> 
        </div> 

      </div>
      
     
      </div>
      <div className='main-content'>
      {loaded === false ? <Loader /> : (
      <Box >
    <DataTable
      noHeader
      records={records}
      columns={[
        { accessor: 'exercise_pics', render: ({exercise_pics}) => (<>{exercise_pics ? <><img className='cta-wrapper' src={ exercise_pics } /> </>: <span className='placeholder'></span>}</>), cellsClassName: 'category_pics', width: 140},
        { accessor: 'exercise', cellsClassName: 'exercise', width: 200},
        { accessor: 'lbs_130', id: 'a', cellsClassName:'col-130lbs', width: 90},
        {accessor: 'lbs_155', name: 'aa', cellsClassName:'col-155lbs', width: 140},
        { accessor: 'lbs_180', name: 'c', cellsClassName:'col-180lbs', width: 140},
        {accessor: 'lbs_205', name: 'd', cellsClassName:'col-205lbs', width: 80},
        {accessor: 'id', cellsClassName:'cta edit',  render: ({id, exercise, lbs_130,lbs_155, lbs_180,lbs_205, exercise_pics}) => (<span onClick={()=>(setEditExercise(true), setUserImg(exercise_pics),  
          formEditExercise.setValues({
            'id': id,  
            'exercise_pics': exercise_pics,
           'exercise': exercise,
            'lbs130': lbs_130,
            'lbs155': lbs_155,
            'lbs180' : lbs_180,
            'lbs205':lbs_205}) )} ><img src={editicon} /></span>),  width: 75},
        {accessor: '', cellsClassName:'cta delete', render: ({id, exercise}) => (<span onClick={()=>(setDeleteExercise(true), formDeleteExercise.setValues({id: id}), setPopopDelete(exercise) )}><img src={deleteicon} /></span>), width: 75}
        
      ]}

    />

{ pagerVis === true  ? ( <>
<div className="mantine-ooem79 pager">
  <NativeSelect label="Exercise display per page" defaultValue={itemInPager} onChange={(e) => {  setItemInPager( e.currentTarget.value)  
  const c = parseInt(((parseInt(numUsers))/e.currentTarget.value), 10);
  const d = parseInt(((parseInt(numUsers))/e.currentTarget.value));
  const b =   c < d ? c+1 : d;

  if(parseInt(numUsers) > parseInt(e.currentTarget.value)){
    if(parseInt(((parseInt(numUsers))/e.currentTarget.value), 10) < (parseInt(numUsers))/e.currentTarget.value){
  seta(parseInt(((parseInt(numUsers))/e.currentTarget.value), 10)+1)}
  else{
    seta(parseInt(((parseInt(numUsers))/e.currentTarget.value), 10))}
  }
  else{
  seta(1)
  }

if(num1>(b-1)){
  
setNum(parseInt(b))
getData(parseInt(b), e.currentTarget.value)

}else{
//console.log("num1 is small"+e)
setNum(num1)
getData(num1, e.currentTarget.value)

}
}} data={['25', '50', '100']} />
  <div className="mantine-Group-root mantine-kn8vaf">
    
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> { pager(-1) }} data-disbaled={num1===0 ? 'disabled' : 'visible'}  type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M7.219 8l3.3 3.3-.943.943L5.333 8l4.243-4.243.943.943-3.3 3.3z" fill="currentColor"></path></svg></button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(-1)}} data-hidden={num1===0 ? 'disabled' : 'visible'} type="button" >{parseInt(num1+1)-1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg active" type="button" >{parseInt(num1+1)}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(1)}} data-hidden={num1===(a-1) ? 'disabled' : 'visible'}  type="button" >{parseInt(num1+1)+1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> {pager(1) }} data-disbaled={num1===(a-1) ? 'disabled' : 'visible'} type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M8.781 8l-3.3-3.3.943-.943L10.667 8l-4.243 4.243-.943-.943 3.3-3.3z" fill="currentColor"></path></svg></button>
    </div>
    </div>
</>) :''}
</Box>
  )}

  



</div>

    </div>

</div>



{ editExercise === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach edit'>
  <span className='close' onClick={()=> (setEditExercise(false), setUserImg(''))}></span>
  <div className='popup-titlebar'>
      <h2>Edit Exercise</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formEditExercise.onSubmit((values) => (handleEdit(values) ))}>     

<div className='two-col-grp'>
  <TextInput className=' two-col invisible'  label="id" required placeholder="id" {...formEditExercise.getInputProps('id')}/>
  <TextInput className=' two-col'  label="Name" required placeholder="Name" {...formEditExercise.getInputProps('exercise')}/>
  <TextInput className='two-col'  label="130lbs"  placeholder="130lbs" {...formEditExercise.getInputProps('lbs130')}/>
        <TextInput className='two-col'  label="155lbs"  placeholder="155lbs" {...formEditExercise.getInputProps('lbs155')}/>
        <TextInput className='two-col'  label="180lbs" placeholder="180lbs" {...formEditExercise.getInputProps('lbs180')} />
        <TextInput className='two-col'  label="205lbs"  placeholder="205lbs" {...formEditExercise.getInputProps('lbs205')}/>

</div>
<div className='img-select'>
            <div className='clickble-area' onClick={()=> { setImgedit(true);  }}> <span className={ userImg ? 'image active' : 'image' }>{ userImg ? <><img src={ userImg } /></> :''} </span>Edit Image </div>
            <TextInput  label=""  placeholder="" {...formEditExercise.getInputProps('exercise_pics')}/>
  </div>

        <Group justify="flex-end" mt="md">
          <Button type="submit" onClick={()=>setloader2(true)}>Save</Button>
        </Group>
      </form>


</div>
</>
):''}

{ addExercise=== true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group assign-coach memrbes-add'>
  <span className='close' onClick={()=> setAddExercise(false)}></span>
  <div className='popup-titlebar'>
      <h2>Add Exercise</h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formAddExercise.onSubmit((values) => (handleSubmit(values), setUserImg('') ))}>     

  

  <div className='two-col-grp'>

  <TextInput className=' two-col'  label="Name" required placeholder="Name" {...formAddExercise.getInputProps('exercise')}/>
  <TextInput className='two-col'  label="130lbs"  placeholder="130lbs" {...formAddExercise.getInputProps('lbs130')}/>
        <TextInput className='two-col'  label="155lbs"  placeholder="155lbs" {...formAddExercise.getInputProps('lbs155')}/>
        <TextInput className='two-col'  label="180lbs" placeholder="180lbs" {...formAddExercise.getInputProps('lbs180')} />
        <TextInput className='two-col'  label="205lbs"  placeholder="205lbs" {...formAddExercise.getInputProps('lbs205')}/>
</div>

<div className='img-select'>
            <div className='clickble-area' onClick={()=> { setimageAssign(true);  }}> <span className={ userImg ? 'image active' : 'image' }>{ userImg ? <><img src={ userImg } /></> :''}</span>Select Image </div>
            <TextInput  label=""  placeholder="" {...formAddExercise.getInputProps('exercise_pics')}/>
  </div>

   

        <Group justify="flex-end" mt="md">
          <Button type="submit" onClick={()=>setloader2(true)}>Save</Button>
        </Group>
      </form>


</div>
</>
):''}


{ imageEdit === true ? (
  <>
  <div className='poup-bg '></div>
<div className='popup add-group delete-group img-popup'>
  <span className='close' onClick={()=> setImgedit('false')}></span>
  <div className='popup-titlebar'>
      <h2>Update Image </h2>
  </div>
  <form >  
    <div className='popup-content'>
       
    <Radio.Group>{imgList !=='No data found' && imgList.map((i, index)=>(
      
           <Radio  value={i.image_url} label="" imgsrc="" onClick={(e)=> (setImgedit('false'), formEditExercise.setValues({ exercise_pics: e.currentTarget.value}), setUserImg(e.currentTarget.value))} style={{ backgroundImage :  'url("'+i.image_url+'")', backgroundSize: 'contain' }}  /> 
          
        ))}
        </Radio.Group>
    </div>


 
</form>

</div>
</>
):''}

{ imageAssign === true ? (
  <>
  <div className='poup-bg '></div>
<div className='popup add-group delete-group img-popup'>
  <span className='close' onClick={()=> setimageAssign('false')}></span>
  <div className='popup-titlebar'>
      <h2>Update Image </h2>
  </div>
  <form >  
    <div className='popup-content'>
       
    <Radio.Group>{imgList !=='No data found' && imgList.map((i, index)=>(
           <Radio  value={i.image_url} label="" imgsrc="" onClick={(e)=> (setimageAssign('false'), formAddExercise.setValues({ exercise_pics: e.currentTarget.value}), setUserImg(e.currentTarget.value))} style={{ backgroundImage :  "url('"+i.image_url+"')", backgroundSize: 'contain' }}  />   
        ))}
        </Radio.Group>
    </div>


 
</form>

</div>
</>
):''}



{ DeleteExercise === true ? (
  <>
  <div className='poup-bg'></div>
<div className='popup add-group delete-group'>
  <span className='close' onClick={()=> setDeleteExercise('')}></span>
  <div className='popup-titlebar'>
      <h2>Delete Exercise </h2>
  </div>
  {error ? <div className='error-msg'>{error}</div> : ''}
  <form onSubmit={formDeleteExercise.onSubmit((values) => (handleDelete(values) ))}>  
    <div className='popup-content'>
      <p>Are you sure you want to delete Exercise <strong>{ popopDelete }</strong> ?</p>
      <TextInput className=' two-col invisible'  label="id" required placeholder="id" {...formDeleteExercise.getInputProps('id')}/>
    </div>

        <Group justify="flex-end" mt="md">
          <Button className='cancel-cta' onClick={()=> setDeleteExercise('')}>Cancel</Button>
          <Button  type='submit'  onClick={()=>setloader2(true)}>Yes</Button>
        </Group>
 
</form>

</div>
</>
):''}


{loader2 === true ? <div className="small-loader"><Loader ></Loader></div> : ''}


</div>








    
  );

}





export default Manageexercise;
