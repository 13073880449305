
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header';
import './Mygroups.css';
import { Box, Grid, TextInput, Checkbox, Group , Button} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { IconSearch, IconCalendar, IconArrowRight, IconPlus, IconPencil, IconTrash } from "@tabler/icons-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link,  useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import dayjs from 'dayjs';
import userImage from '../../images/user.svg';


function Mygroups() {

  localStorage.setItem("DATE", (moment()).format('YYYY-MM-DD'));

const [loaded, dataLoading] = useState(false);
const [error, setError] = useState(false);
const user = localStorage.getItem("user").replace(/['"]+/g, '');
const role =localStorage.getItem("userrole");
const userName = localStorage.getItem("userName");
const memberGp = localStorage.getItem('userGroup');

const[assinngGroup, setAssingGroup] = useState('');
const [gorupsData, setGroupData] = useState([]);
const [memberList, setMemberList] = useState([]);


const [num1, setNum] = useState();
const base_url = process.env.REACT_APP_BASE_URL;
const [filterdGroup, setFilteredGroup] = new useState([]);

const navigate = useNavigate();
const logout = (e) => {
  let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
  navigate('/Login');
}


function getData(){
  
  axios({ method: "POST", url:base_url+"/admin/coach-group-name", data:{"email": localStorage.getItem('userEmail')}, crossDomain: true, headers: {"Authorization" : `Bearer ${ user }`},}).then((response) => {
    dataLoading(true)
      if(response.data.status===200){ setGroupData(response.data.response); setNum(1); setFilteredGroup(response.data.response) }else{ setGroupData('No data')}
  }).catch((e) => {
    if(e.message==="Request failed with status code 401"){
      logout();
    }
  })
}



const [switchData, setSwithdata] = useState('userlist');
const [gname, setgname] = useState();




/* const logoutforadmin = (e) => {
  let keysToRemove = ["user", "DATE", "userrole", "userName", "email"];
  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
  navigate('/Adminlogin');
} */

const [pagerVis, setPagerVis] = useState(true);
const [searchActive, setSearchActive] = useState(false);
     useEffect(() => {
      getData()
      //setSwithdata(localStorage.getItem('group'))
      if(searchActive===false){
        getData();
        }
  }, [num1, loaded]);


  const [filteredList, setFilteredList] = new useState(memberList);
function filterBySearch (event) {
    const query = event.target.value;
    var updatedList = [...memberList];
    updatedList = memberList.filter((item) => {
      return item?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(updatedList);
  };

  function filterByGroup (event){
    const query = event.target.value;
    var updatedList = [...gorupsData];
   // console.log(query)
    updatedList = gorupsData.filter((item) => {
      return item?.group_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredGroup(updatedList);
  };

function filterClear(){
    setFilteredList(memberList);
}




function blurSerach(e){
 // console.log(e.target.value)
  if(e.target.value === '' || e.target.value ===null){setSearchActive(false)}
}

function userMemberget(e){
  setSwithdata('grouplist');
  localStorage.setItem('userGroup', e)
}

  return (
    
    <div className="Dashboard" >
      <div className="page-wrpper"> 
      <Header />
      <div className='content-wrapper' >
        <div className='header-area'>
         
          <div className='header-top'>
            <div className='left-content' >
            <h1>Groups List </h1>
            </div>

            
            <div className='right-content'>
            { role ==='admin' ? <Link className='adminlogin' to={'/Adminlogin'} target="_blank">Database Admin</Link> : ''}
            <span className='current-user'><img alt="user" src={ userImage } />
           <span className='user-details'><span className='username'>{ userName }</span> <span className='userrole'>{ role }</span></span>
           
           <span className='user-popup'><button onClick={ logout }>Logout</button></span>
           </span>
            </div> 
          </div>
          
 
          
          <div className='header-left'>
          { gorupsData!== 'No data found' && gorupsData !== 'No data' && switchData === "grouplist" ?  '' : 
           <Grid align="center" mb="md">
            <Grid.Col xs={8} sm={9}>
              <TextInput sx={{ flexBasis: '60%' }} onFocus={()=> setSearchActive(true)} onBlur={(value)=> blurSerach(value)} onChange={(value)=> filterByGroup(value)} placeholder="Search Group by Name" icon={<IconSearch size={16} />}    />
            </Grid.Col></Grid>
             }
        </div> 

        <div className='header-right'>
        

      </div>

      </div>
      <div className='main-content groups-data'>
      
      

        
      <Box >
        
    {gorupsData !== "No data found" && gorupsData !== "No data"  ? <DataTable
      noHeader
      records={filterdGroup}
      columns={[
        {cellsClassName: 'group-name', accessor: 'group_name', render: ({group_name, members}) => (
          <>{parseInt(members) > 0 ? <Link onClick={() => localStorage.setItem('userGroup', group_name)} to={'/Memberlist'}>{/* <span onClick={() => openGoupList(group_name)}> */}{group_name}{/* </span> */}</Link> : `${group_name}`}</>
          ), width: 300},
        {cellsClassName: 'members', width: 200, render : ({members}) => `${members}`+' Members', accessor: 'Members'},
        {accessor: 'coaches', cellsClassName:'coaches', width: 250},
      ]}

    /> : ''}

{ pagerVis === true  ? ( <>
<div className="mantine-ooem79 pager">
  <div className="mantine-Group-root mantine-kn8vaf">
    {/* <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> { pager(-1) }} data-disbaled={num1===1 ? 'disabled' : 'visible'}  type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M7.219 8l3.3 3.3-.943.943L5.333 8l4.243-4.243.943.943-3.3 3.3z" fill="currentColor"></path></svg></button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(-1)}} data-hidden={num1===1 ? 'disabled' : 'visible'} type="button" >{num1-1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg active" type="button" >{num1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-ua81gg" onClick={()=> {pager(1)}} data-hidden={num1===a ? 'disabled' : 'visible'}  type="button" >{num1+1}</button>
    <button className="mantine-UnstyledButton-root mantine-Pagination-control mantine-4clav0" onClick={()=> {pager(1) }} data-disbaled={num1===a ? 'disabled' : 'visible'} type="button" ><svg width="calc(1.625rem / 2)" height="calc(1.625rem / 2)" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className="mantine-2jxpqh"><path d="M8.781 8l-3.3-3.3.943-.943L10.667 8l-4.243 4.243-.943-.943 3.3-3.3z" fill="currentColor"></path></svg></button>
 */}    </div>
    </div>
</>) :''}
</Box>






{loaded === false ? <div className='global-loader'><Loader /></div> : '' }





</div>

    </div>

</div>


</div>

    
  );

}





export default Mygroups;
