import React from "react";
import logo from './images/logo.png'
import { useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { IconArrowBackUp } from "@tabler/icons-react";
import "./headeradmin.css";
import coachIco from './images/coach.svg';
import { useEffect } from "react";


function Headeradmin(){

    const [backL, bck] = useState();
    const location = useLocation().pathname.toLowerCase();
  


useEffect(() => {
   // console.log(gorupNode)

}, [])


    return(
        <div id="header" >
            <a href="/" ><img alt="Clova" src={logo}></img></a>
           
           <div className="board-links">
            <Link to={'/Managemeal'}   className='board-link groups'  activemenu={location==='/managemeal'? 'active' : '' }> <span ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 3H5C4.33696 3 3.70107 3.26339 3.23223 3.73223C2.76339 4.20107 2.5 4.83696 2.5 5.5V18.5C2.5 19.163 2.76339 19.7989 3.23223 20.2678C3.70107 20.7366 4.33696 21 5 21H15" stroke="#0051A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.5 3V11.762L2.5 14M9.5 12L15.5 14" stroke="#0051A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.5 9V21" stroke="#0051A6" strokeWidth="1.5" strokeLinecap="round"/>
<path d="M15.5 6.6665C15.5 3.5555 17.5 2 18.5 2C19.5 2 21.5 3.5555 21.5 6.6665C21.5 9.778 15.5 9.778 15.5 6.6665Z" stroke="#0051A6" strokeWidth="1.5"/>
</svg></span>  Manage Meal</Link>

<Link to={'/Manageexercise'}   className='board-link groups' activemenu={location==='/manageexercise'? 'active' : '' }> <span ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.4748 9.29365L14.6748 4.49365L15.0998 4.06865C15.3038 3.86465 15.5511 3.76365 15.8418 3.76565C16.1331 3.76765 16.3808 3.87065 16.5848 4.07465L19.9048 7.41465C20.1095 7.61865 20.2118 7.86199 20.2118 8.14465C20.2118 8.42732 20.1098 8.67065 19.9058 8.87465L19.4748 9.29365ZM8.8228 19.9767C8.63213 20.1673 8.39546 20.2693 8.1128 20.2827C7.82946 20.2953 7.59246 20.206 7.4018 20.0147L4.0228 16.6547C3.8188 16.4513 3.7168 16.205 3.7168 15.9157C3.7168 15.627 3.8188 15.3803 4.0228 15.1757L4.5188 14.6807L9.3188 19.4807L8.8228 19.9767ZM12.1398 19.9117C11.9785 20.073 11.7901 20.1537 11.5748 20.1537C11.3595 20.1537 11.1711 20.073 11.0098 19.9117L4.0878 12.9897C3.92646 12.8283 3.8458 12.64 3.8458 12.4247C3.8458 12.2093 3.92646 12.021 4.0878 11.8597L5.5708 10.3707C5.73213 10.2093 5.92146 10.1287 6.1388 10.1287C6.35613 10.1287 6.5458 10.2093 6.7078 10.3707L8.5708 12.2537L12.2598 8.56465L10.3768 6.70165C10.2155 6.54032 10.1348 6.35199 10.1348 6.13665C10.1348 5.92132 10.2155 5.73265 10.3768 5.57065L11.8598 4.06265C12.0211 3.90132 12.2138 3.82065 12.4378 3.82065C12.6611 3.82065 12.8535 3.90132 13.0148 4.06265L19.9368 10.9847C20.0981 11.146 20.1788 11.3387 20.1788 11.5627C20.1788 11.786 20.0981 11.9783 19.9368 12.1397L18.4288 13.6227C18.2675 13.784 18.0788 13.8647 17.8628 13.8647C17.6475 13.8647 17.4591 13.784 17.2978 13.6227L15.4348 11.7397L11.7458 15.4287L13.6288 17.2917C13.7901 17.4537 13.8708 17.6433 13.8708 17.8607C13.8708 18.078 13.7901 18.2673 13.6288 18.4287L12.1398 19.9117Z" fill="#7D7D7D"/>
</svg></span>  Manage Exercise</Link>

<Link to={'/Manageimages'}   className='board-link groups' activemenu={location==='/manageimages'? 'active' : '' }> <span ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM14.14 11.86L11.14 15.73L9 13.14L6 17H18L14.14 11.86Z" fill="#7D7D7D"/>
</svg>
</span>  Manage Images</Link>

            </div>
            
        </div>

        

    );
}

export default Headeradmin;